.payslip-no-data-box[data-v-32b65562] {
  background: #fff;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}
@media screen and (min-width: 768px) {
.payslip-no-data-box[data-v-32b65562] {
    width: 375px;
    height: 667px;
    margin: 0 auto;
}
}
